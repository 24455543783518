import { NavLink, Switch } from 'react-router-dom'
import Layout from './hoc/Layout/Layout'
import MainScreen from './containers/MainScreen/MainScreen'
import './App.scss'

function App() {
  return (
    <div className="App">
      <Layout>
        <Switch>
          <NavLink to='/' exact component={MainScreen} />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;

import actionTypes from '../actions/actionTypes'

const initialState = {
  accessToken: '',
  refreshToken: '',
  promo: '',
  integration: false,
  testProgress: '',
  currentProject: '',
  name: {}
}

function userReducer(state = initialState, action) {

  switch(action.type) {
    case actionTypes.setAccessToken:
      return {
        ...state,
        accessToken: action.token
      }
    case actionTypes.setRefreshToken:
      return {
        ...state,
        refreshToken: action.token
      }
    case actionTypes.setPromo:
      return {
        ...state,
        promo: action.promo
      }
    case actionTypes.setIntegration:
      return {
        ...state,
        integration: action.integration
      }
    case actionTypes.setUserData:
      return {
        ...state,
        name: action.data
      }
    case actionTypes.setCurrentProject:
      return {
        ...state,
        currentProject: action.hash
      }
    default:
      return state
  }

}

export default userReducer;
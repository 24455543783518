import actionTypes from '../actions/actionTypes'
import {IDevReducerState} from "../../types/reducerTypes";

const initialState: IDevReducerState = {
  dev: '', // имя разработчика, список ниже
  isDev: false, // общее, для проверки
  devPanelOpen: false, // это для дев-панели
  autoFinishTest: false, // это для дев-панели

  ifTestHasAuto: false, // на это ориентируется дев-панель
  ifTestAlreadyAutoChosen: false, // на это ориентируется обертка теста (Test)
  ifAutoButtonPressed: false //  на это ориентируется тест (TextTest, ImageTest, ...)
}

const developers = [
  'am',
  'kiri',
  'sales'
]


function devReducer(state = initialState, action: any) {
  switch(action.type) {
    case actionTypes.setDeveloper:
      if (developers.indexOf(action.dev) > -1) {
        return {
          ...state,
          dev: action.dev,
          isDev: true
        }
      } else {
        return state
      }
    case actionTypes.setDevPanelToggle:
      if (state.isDev) {
        return {
          ...state,
          devPanelOpen: !state.devPanelOpen
        }
      } else {
        return state
      }
    case actionTypes.setDevAutoFinishOn:
      if (state.isDev) {
        return {
          ...state,
          autoFinishTest: true
        }
      } else {
        return state
      }
    case actionTypes.setDevAutoFinishOff:
      if (state.isDev) {
        return {
          ...state,
          autoFinishTest: false
        }
      } else {
        return state
      }
      ///////
    case actionTypes.setIfTestHasAuto:
      if (state.isDev) {
        return {
          ...state,
          ifTestHasAuto: action.bool
        }
      } else {
        return state
      }
    case actionTypes.setIfTestAlreadyAutoChosen:
      if (state.isDev) {
        return {
          ...state,
          ifTestAlreadyAutoChosen: action.bool
        }
      } else {
        return state
      }
    case actionTypes.setIfAutoButtonPressed:
      if (state.isDev) {
        return {
          ...state,
          ifAutoButtonPressed: action.bool
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default devReducer;
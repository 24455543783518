import './MenuBurgerButton.scss'
import BurgerSVG from '../../../assets/ui/BurgerSVG.svg'
import BurgerOpenSVG from '../../../assets/ui/BurgerOpenSVG.svg'
import PersonSVG from '../../../assets/ui/PersonSVG.svg'

function MenuBurgerButton(props: Props) {

// ================================= LAYOUT =================================

  return (
    <div className={"menu-burger-button" + (props.isMenuOpen ? " -open" : " -close") + (props.route ? (" --" + props.route) : "") + ' ' + props.className} onClick={props.onClick}>
      {props.isMenuOpen ? <img className="menu-button__image -burger" src={BurgerOpenSVG} alt="cross"/> : <img className="menu-button__image -burger" src={BurgerSVG} alt="cross"/>}
      <img className="menu-button__image-user" src={PersonSVG} alt="cross"/>
    </div>
  );
}

// ================================= PROPS =================================

interface Props {
  isMenuOpen: boolean,
  onClick: () => void,
  route: string,
  className: string
}

// MenuBurgerButton.defaultProps = {
//   isMenuOpen: true
// }

// ================================= EXPORT =================================

export default MenuBurgerButton;
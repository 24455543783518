import { NavLink, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import './SideMenu.scss'
import { setUserData, setAccessToken, setCurrentProject } from '../../redux/actions/actions'

function SideMenu(props: Props) {

// ================================= FUNCTIONS =================================

  // Переменная для использования функции редиректа
  const history = useHistory()

  // Функция для кнопки 'Выйти'
  const logoutFunction = () => {
    if (window.confirm("Выйти из аккаунта?"))
    { props.reduxActions.setAccessToken('')
      props.reduxActions.setUserData({})
      props.reduxActions.setCurrentProject('')
      localStorage.clear()
      history.push('/auth')
    }
    props.toggleMenu()
  }

  // Массив пунктов для панели главного меню
    const links = [
        {to: '/', label: 'Тесты', exact: true},
        {to: '/auth', label: 'Войти', exact: true},
        {to: '/sign-up', label: 'Регистрация', exact: true},
        {to: '/test', label: 'Текущий тест', exact: true},
    ]

  // Рендерим все элементы массива links в JSX
  const renderLinks = () => {
    return links.map((link, index) => {

        if ((link.to === '/auth' || link.to === '/sign-up') && props.userData.accessToken.length > 0) { return null }
        if (link.to === '/' && props.userData.accessToken.length === 0) { return null }
        if ((link.to === '/test' && !props.userData.currentProject) || (link.to === '/test' && props.userData.currentProject.length === 0)) { return null }

        return (
          <NavLink
            key={index}
            to={link.to}
            exact={link.exact}
            className="menu-item"
            activeClassName="menu-item-active"
            style={{ color: 'inherit', textDecoration: 'inherit'}}
            onClick={props.toggleMenu}
          >
            {link.label}
          </NavLink>
        )
      })
    }

  // ================================= LAYOUT =================================

    return (
      <>
        <nav className={"side-menu " + props.className}>

          {/*<div className="person-label">*/}
          {/*  {(props.userData.name?.first_name === undefined && props.userData.name?.last_name === undefined) && */}
          {/*    <div style={{fontSize: 44, letterSpacing: 3}}>Skillfolio</div>*/}
          {/*  }*/}
          {/*  {(props.userData.name?.first_name !== undefined && props.userData.name?.last_name !== undefined) && */}
          {/*    <>*/}
          {/*      <img className="person-img" src={PersonSVG} alt="" />*/}
          {/*      <p className="person-label--name">{props.userData.name.first_name} {props.userData.name.last_name}</p>*/}
          {/*    </> */}
          {/*  }*/}
          {/*</div>*/}

          <div className="menu-wrapper">
            {renderLinks()}
            {props.userData.accessToken?.length > 0 &&
              <div className="menu-item" onClick={logoutFunction}>
                Выйти
              </div>
            }
          </div>

        </nav>
        <div className={"side-menu__blackout " + props.className} onClick={props.toggleMenu}/>
      </>
    );
}

// ================================= PROPS =================================

// Описываем, какие поля МОЖЕТ принимать props:
interface Props {
  userData: { accessToken: '', currentProject: '', name: { first_name: '', middle_name: '', last_name: '' } },
  reduxActions: { 
    setAccessToken: (token: string) => void,
    setUserData: (data: object) => void,
    setCurrentProject: (hash: string) => void,
  },
  className?: string,
  toggleMenu: () => void
}

SideMenu.defaultProps = {
  className: ""
}

// ================================= REDUX =================================

// Загружаем данные из store в props:
function mapStoreToProps(store: any): object {
  return {
    userData: { ...store.userData }
  }
}

// Передаем в props объект reduxActions, который содержит в себе экшны для изменения store.
function mapDispatchToProps(dispatch: any): object {
  return {
    reduxActions: { 
      setAccessToken: (token: string) => dispatch(setAccessToken(token)),
      setUserData: (data: object) => dispatch(setUserData(data)),
      setCurrentProject: (hash: string) => dispatch(setCurrentProject(hash))
    }
  }
}

// ================================= EXPORT =================================

export default connect(mapStoreToProps, mapDispatchToProps)(SideMenu);
const actionTypes = {
  setAccessToken: 'SET_ACCESS_TOKEN',
  setRefreshToken: 'SET_REFRESH_TOKEN',
  setPromo: 'SET_PROMO',
  setUserData: 'SET_USER_DATA',
  setProjects: 'SET_PROJECT_DATA',
  setCurrentProject: 'SET_CURRENT_TEST',
  setIntegration: 'SET_INTEGRATION',
  // DEV ACTIONS
  setDeveloper: '__DEV__SET_DEVELOPER',
  setDevPanelToggle: '__DEV__SET_DEV_PANEL_TOGGLE',
  setDevAutoFinishOn: '__DEV__SET_DEV_AUTO_FINISH_ON',
  setDevAutoFinishOff: '__DEV__SET_DEV_AUTO_FINISH_OFF',

  setIfTestHasAuto: '__DEV__SET_IF_TEST_HAS_AUTO',

  setIfTestAlreadyAutoChosen: '__DEV__SET_IF_TEST_ALREADY_AUTO_CHOSEN',

  setIfAutoButtonPressed: '__DEV__SET_IF_AUTO_BUTTON_PRESSED',
}

export default actionTypes;
import actionTypes from './actionTypes'

// Пример синхронного action
export function setAccessToken(token: string) {
  return {
    type: actionTypes.setAccessToken, token 
  }
}

// Пример асинхронного action
export function asyncSetAccessToken(token: string) {
  return async (dispatch: any) => {
    await dispatch({ type: actionTypes.setAccessToken, token })
  }
}

export function setRefreshToken(token: string) {
  return async (dispatch: any) => {
    await dispatch({ type: actionTypes.setRefreshToken, token })
  }
}

export function setPromo(token: string) {
  return async (dispatch: any) => {
    await dispatch({ type: actionTypes.setPromo, token })
  }
}

export function setIntegration(integration: boolean) {
  return {
    type: actionTypes.setIntegration, integration
  }
}

export function setUserData(data: object) {
  return {
    type: actionTypes.setUserData, data
  }
}

export function setCurrentProject(hash: string) {
  return {
    type: actionTypes.setCurrentProject, hash
  }
}

export function setProjects(data: object) {
  return {
    type: actionTypes.setProjects, data
  }
}

/////////// dev actions ////////////////////////

export function setDeveloper(dev: string) {
  return {
    type: actionTypes.setDeveloper, dev
  }
}
///----dev-panel------///
export function setDevPanelToggle() {
  return {
    type: actionTypes.setDevPanelToggle
  }
}
///----------///

///----auto-finish-----///
export function setDevAutoFinishOn() {
  return {
    type: actionTypes.setDevAutoFinishOn
  }
}

export function setDevAutoFinishOff() {
  return {
    type: actionTypes.setDevAutoFinishOff
  }
}
///----------///


///-----dev panel working mechanism-----///
export function setIfTestHasAuto(bool: boolean) {
  return {
    type: actionTypes.setIfTestHasAuto, bool
  }
}
export function setIfTestAlreadyAutoChosen(bool: boolean) {
  return {
    type: actionTypes.setIfTestAlreadyAutoChosen, bool
  }
}
export function setIfAutoButtonPressed(bool: boolean) {
  return {
    type: actionTypes.setIfAutoButtonPressed, bool
  }
}
///------------///
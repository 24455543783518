import actionTypes from '../actions/actionTypes'

const initialState = {

}

function projectReducer(state = initialState, action) {

  switch(action.type) {
    case actionTypes.setProjects:
      return action.data
    default:
      return state
  }

}

export default projectReducer;
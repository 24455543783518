import './Loader.scss'

function Loader(props: { size?: string}) {
  return (
    <div className="loader-wrapper" style={props.size ? { height: props.size, width: props.size } : {}}>
      <div className="loader" style={props.size ? { height: props.size, width: props.size } : {}} >
        <div style={props.size ? { height: props.size, width: props.size } : {}} ></div>
        <div style={props.size ? { height: props.size, width: props.size } : {}}></div>
        <div style={props.size ? { height: props.size, width: props.size } : {}}></div>
        <div style={props.size ? { height: props.size, width: props.size } : {}}></div>
      </div>
    </div>
  )
}

export default Loader
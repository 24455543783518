import './MainScreen.scss'
import {useMemo, lazy, Suspense, useEffect} from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import SideMenu from "../SideMenu/SideMenu";
import MenuBurgerButton from "../../components/ui/MenuBurgerButton/MenuBurgerButton";
import Loader from "../../components/ui/Loader/Loader"
import {useState} from "react";
import {connect} from "react-redux";
import {
  setAccessToken,
  setCurrentProject,
  setDeveloper,
  setIntegration,
  setUserData
} from "../../redux/actions/actions";

import {IDevReducerState} from "../../types/reducerTypes";

function MainScreen(props: Props) {

// ================================= STATE =================================

  const [state, setState] = useState({
    isMenuOpen: false
  })

// ================================= FUNCTIONS =================================

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery()

  useEffect(() => {

    const dev = query.get('dev')

    if (dev) {
      props.reduxActions.setDeveloper(dev)
      window.location.href = window.location.origin + window.location.pathname + window.location.hash
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


// ================================= MOBILE =================================

  function toggleMenu() {
    setState({isMenuOpen: !state.isMenuOpen})
  }

  const location = useLocation().pathname.split("/")[1];

// ================================= DYNAMIC IMPORTS =================================

  const Auth = useMemo(() => lazy(() => import('../Auth/Auth')), [])
  const ResetPassword = useMemo(() => lazy(() => import('../Auth/ResetPassword')), [])
  const SignUp = useMemo(() => lazy(() => import('../Auth/SignUp')), [])
  const AllTests = useMemo(() => lazy(() => import('../AllTests/AllTests')), [])
  const Test = useMemo(() => lazy(() => import('../Test/Test')), [])
  const SharedResults = useMemo(() => lazy(() => import('../Results/SharedResults')), [])
  const TestConstructor = useMemo(() => lazy(() => import("../Constructor/Constructor")), [])


  const logoutFunction = () => {
    props.reduxActions.setAccessToken('')
      props.reduxActions.setUserData({})
      props.reduxActions.setCurrentProject('')
      props.reduxActions.setIntegration(false)
      localStorage.clear()
  }

// ================================= LAYOUT =================================

  return (
    <div className="main-screen">
      <MenuBurgerButton isMenuOpen={state.isMenuOpen} onClick={() => toggleMenu()} route={location} className={props.userData.integration ? '-hidden' : ''} />
      <SideMenu
        userData={{ accessToken: '', currentProject: '', name: { first_name: '', middle_name: '', last_name: '' } }} 
        reduxActions={{ 
          setAccessToken: (token: string) => {}, 
          setUserData: (data: object) => {},
          setCurrentProject: (hash: string) => {}
        }}
        className={props.userData.integration ? '-hidden' : state.isMenuOpen ? "-opened" : "-closed"}
        toggleMenu={toggleMenu}
      />

      <div className="right-window">
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/" exact component={AllTests} />
            <Route path="/auth" exact component={Auth} />
            <Route path="/sign-up" exact component={SignUp} />
            <Route path="/reset-password/:resetToken" component={ResetPassword} />
            <Route path="/test" exact component={Test} />
            <Route path="/results/:resultHash" component={SharedResults} />
            <Route path="/constructor" component={TestConstructor} />
            {/*<Route component={AllTests} />*/}
            <Route path="/quit" render={() => {
              logoutFunction()
              return <Redirect to="/auth" />
            }}/>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </div>
  );
}

// ================================= PROPS =================================

// Описываем, какие поля МОЖЕТ принимать props:
interface Props {
  userData: { accessToken: string, refreshToken: string, integration: boolean },
  history: { push: (link: string) => void, location: any},
  devData: IDevReducerState
  reduxActions: {
    setAccessToken: (token: string) => void,
    setUserData: (data: object) => void,
    setCurrentProject: (hash: string) => void,
    setIntegration: (integration: boolean) => void,
    setDeveloper: (dev: string) => void
  }
}

// ================================= REDUX =================================

// Загружаем данные из store в props:
function mapStoreToProps(store: any): object {
  return {
    userData: { ...store.userData },
    devData: { ...store.devData }
  }
}

// Передаем в props объект reduxActions, который содержит в себе экшны для изменения store.
function mapDispatchToProps(dispatch: any): object {
  return {
    reduxActions: {
      setAccessToken: (token: string) => dispatch(setAccessToken(token)),
      setUserData: (data: object) => dispatch(setUserData(data)),
      setCurrentProject: (hash: string) => dispatch(setCurrentProject(hash)),
      setIntegration: (integration: boolean) => dispatch(setIntegration(integration)),
      setDeveloper: (dev: string) => dispatch(setDeveloper(dev))
    }
  }
}

// ================================= EXPORT =================================

export default connect(mapStoreToProps, mapDispatchToProps)(MainScreen);

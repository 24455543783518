import "./Layout.scss"

function Layout(props: { children?: JSX.Element }) {
  
  return (
    <div className="lay-div">
      <main className="lay-main">
        { props.children }
      </main>
    </div>
  )
}

export default Layout;
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import {createStore, applyMiddleware, compose} from 'redux'
import { Provider } from 'react-redux'
import reduxThunk from 'redux-thunk'
import rootReducer from './redux/rootReducer'
import './index.scss';
import App from './App';

// Привязываем store к localStorage
////////////////////////////////////////////////////////////////
const storeLogger = store => next => action => {

  const result = next(action)
  if (action.type && action.type.indexOf('__DEV__') > -1) {
    console.debug('DEV', store.getState())
  } else {
    console.log('Store:', store.getState())
  }
  return result
}

const persistedState = localStorage.getItem('reduxState') 
                       ? JSON.parse(localStorage.getItem('reduxState'))
                       : {}

const store = createStore(rootReducer, persistedState,
  compose(
    applyMiddleware(storeLogger, reduxThunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose
  ))

store.subscribe(()=> {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})
////////////////////////////////////////////////////////////////

const app = (
  <Provider store={store}>
    <BrowserRouter>
     <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(
  app,
  document.getElementById('root')
);
